<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <header class="bg-light px-5 py-4">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <section>
        <h3>
          <span
            class="bi"
            :class="[`bi-${icon}`]"
          />
          {{ title }}
        </h3>
      </section>
      <section class="w-auto">
        <div class="d-flex justify-content-center align-items-center">
          <div class="border-end border-dark pe-3 py-2">
            <div class="lh-1 text-end">
              <span class="text-nowrap">
                {{ userStore.currentUser?.businessName }}
              </span>
              <br>
              <small class="text-secondary">
                {{ userStore.currentUser?.email }}
              </small>
            </div>
          </div>

          <div class="ps-3">
            <img
              v-if="userStore.currentUser.logo"
              class="img-fluid"
              alt="user-logo"
              :src="`${apiUrl}/${userStore.currentUser.logo}`"
            >
          </div>
          <component
            :is="numberOfCartItems > 0 ? 'router-link' : 'span'"
            v-if="userStore.userOperations.menuItems?.cart"
            class="ps-5"
            to="/checkout/cart"
          >
            <button class="btn btn-sm btn-primary rounded-circle">
              <span class="bi bi-cart" />
              <small
                id="cart-counter"
                class="position-absolute badge rounded-pill text-dark fw-normal bg-white"
              >
                {{ numberOfCartItems }}
              </small>
            </button>
          </component>
        </div>
      </section>
    </div>
  </header>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import NavigationService from '@/services/NavigationService';
import {useUserStore} from '@/stores/UserStore';
import {useCartStore} from '@/stores/CartStore';

export default defineComponent({
  name: 'MainHeader',
  setup: () => {
    return {
      navigationService: new NavigationService(),
      userStore: useUserStore(),
      cartStore: useCartStore(),
    };
  },
  computed: {
    apiUrl(): string {
      return process.env.VUE_APP_API_URL;
    },
    title() {
      const currentLink = this.navigationService.getActiveNavigationLink();
      if (currentLink) {
        return currentLink.title;
      }
      if (this.$route.meta.title) {
        return this.$route.meta.title;
      }
      return '';
    },
    icon() {
      const currentLink = this.navigationService.getActiveNavigationLink();
      if (currentLink) {
        return currentLink.icon;
      }
      if (this.$route.meta.icon) {
        return this.$route.meta.icon;
      }
      const parentIconRoutes = this.$route.matched.find((route) => route.meta.icon);
      if (parentIconRoutes) {
        return parentIconRoutes.meta.icon;
      }
      return '';
    },
    numberOfCartItems() {
      if (this.cartStore.cart.products) {
        return this.cartStore.cart.products.length;
      }
      return 0;
    },
  },
  watch: {
    'userStore.currentUser.role': async function() {
      if (!this.userStore.userOperations.menuItems || !this.userStore.userOperations.menuItems.cart) {
        return;
      }
      await this.cartStore.get();
    },
  },
  created() {
    if (!this.userStore.userOperations.menuItems || !this.userStore.userOperations.menuItems.cart) {
      return;
    }
    this.cartStore.get();
  },
});
</script>

<style scoped>
  img {
    width: auto;
    height: 2.25rem;
  }
  #cart-counter {
    top: 1.5rem;
    right: 2.25rem;
  }
</style>
