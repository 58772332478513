<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <ul class="nav nav-pills flex-column px-2">
    <li
      v-for="link in links"
      :key="link.name"
      class="nav-item p-2"
    >
      <router-link
        class="nav-link rounded-4 py-3 mx-2"
        :class="[ isLinkActive(link) ? 'active' : 'link-dark' ]"
        :to="{'name': link.routeName}"
      >
        <span
          class="bi px-2"
          :class="[`bi-${link.icon}`]"
        />
        {{ link.name }}
      </router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import NavigationService from '@/services/NavigationService';
import NavigationLink from '@/types/NavigationLink';
import {useUserStore} from '@/stores/UserStore';

export default defineComponent({
  name: 'MainNav',
  setup: () => {
    return {
      navigationService: new NavigationService(),
      navigationLinksToDisplay: [
        'Dashboard',
        'Telai',
        'Ordini',
        'Documenti',
        'Prodotti',
        'Utenti',
      ],
      userStore: useUserStore(),
    };
  },
  computed: {
    links: function() {
      return this.navigationService.getNavigationLinks()
          .filter((link: NavigationLink) => this.navigationLinksToDisplay.includes(link.name));
    },
  },
  methods: {
    isLinkActive: function(link: NavigationLink): boolean {
      if (this.$route.name === link.routeName) {
        return true;
      }
      const DetailPageName = link.routeName.replace('List', 'Detail');
      if (this.$route.name === DetailPageName) {
        return true;
      }
      return false;
    },
  },
});
</script>
