<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="pt-2 pb-1">
    <ul class="nav nav-pills flex-column">
      <li
        v-for="link in links"
        :key="link.name"
        class="nav-item"
      >
        <router-link
          class="nav-link link-dark rounded-4 py-2"
          :to="{'name': link.routeName}"
        >
          <span
            class="bi px-2"
            :class="[`bi-${link.icon}`]"
          />
          {{ link.name }}
        </router-link>
      </li>
    </ul>
    <hr
      v-if="userStore.userOperations.menuItems?.downloadManual"
      class="text-white my-1"
    >
    <div
      v-if="userStore.userOperations.menuItems?.downloadManual"
      class="ps-4"
    >
      <select
        ref="manualNameSelect"
        class="nav-link link-dark ps-2 py-2 form-control border-0 form-select bg-gray2"
        @click="downloadManual"
      >
        <option
          value=""
          selected
          disabled
          hidden
        >
          Manuali
        </option>
        <option value="manuale_uso.pdf">
          Manuale d'uso
        </option>
        <option value="manuale_uso_collaudi_km0.pdf">
          Manuale d'uso Collaudo a Km 0
        </option>
      </select>
      <a
        ref="manualLink"
        class="d-none"
        href=""
        target="_blank"
      >
      </a>
    </div>
    <hr class="text-white my-1">
    <button
      v-if="userStore.userOperations.menuItems?.logout"
      class="btn btn-link text-secondary text-decoration-none"
      @click="logout"
    >
      <span
        class="bi bi-door-closed"
      />
      Logout
    </button>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import {useSessionStore} from '@/stores/SessionStore';
import {useUserStore} from '@/stores/UserStore';
import NavigationService from '@/services/NavigationService';
import NavigationLink from '@/types/NavigationLink';

export default defineComponent({
  name: 'MainActions',
  setup: () => {
    return {
      navigationService: new NavigationService(),
      navigationLinksToDisplay: [
        'Profilo',
        'Credenziali',
        'Indirizzi',
      ],
      sessionStore: useSessionStore(),
      userStore: useUserStore(),
      manualNameSelect: ref({} as HTMLSelectElement),
      manualLink: ref({} as HTMLLinkElement),
    };
  },
  computed: {
    links: function() {
      return this.navigationService.getNavigationLinks()
          .filter((link: NavigationLink) => this.navigationLinksToDisplay.includes(link.name));
    },
  },
  methods: {
    logout: function() {
      this.sessionStore.logout();
      this.$router.push({name: 'LoginPage'});
      window.location.reload();
    },
    downloadManual: function() {
      if (this.manualNameSelect.value === '') {
        return;
      }

      this.manualLink.href = '/assets/' + this.manualNameSelect.value;
      this.manualLink.click();
      this.manualNameSelect.value = '';
    },
  },
});
</script>

<style scoped lang="scss">
.nav-link, .btn-link {
  font-size: 0.8rem;
}
hr {
  border: 2px solid #fff;
}
</style>
