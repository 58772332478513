/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {UserInterface, UserRole} from '@/types/entities';

export default class User implements UserInterface {
  id: number;
  email: string;
  phone: string;
  active: boolean;
  role: keyof typeof UserRole;
  changePassword: boolean;
  dealerCode: string;
  fiscalCode: string | null;
  SDICode: string | null;
  PECAddress: string | null;
  businessName: string | null;
  contactName: string | null;
  legalRepresentative: string | null;
  vatNumber: string | null;
  shippingCost: number | null;
  invoicingType: string | null;
  logo: string | null;
  mainUser: User | null;
  carMaker: User | null;
  password?: string;
  courtesyInvoiceEmail: string | null;

  constructor(data: UserInterface) {
    this.id = data.id;
    this.email = data.email;
    this.phone = data.phone;
    this.role = data.role;
    this.changePassword = data.changePassword;
    this.dealerCode = data.dealerCode;
    this.active = data.active;
    this.fiscalCode = data.fiscalCode;
    this.SDICode = data.SDICode;
    this.PECAddress = data.PECAddress;
    this.businessName = data.businessName;
    this.contactName = data.contactName;
    this.legalRepresentative = data.legalRepresentative;
    this.vatNumber = data.vatNumber;
    this.shippingCost = data.shippingCost;
    this.invoicingType = data.invoicingType;
    this.logo = data.logo;
    this.mainUser = data.mainUser ? new User(data.mainUser as UserInterface) : null;
    this.carMaker = data.carMaker ? new User(data.carMaker as UserInterface) : null;
    this.password = data.password;
    this.courtesyInvoiceEmail = data.courtesyInvoiceEmail;
  }

  get roleLabel(): string {
    if (this.role === 'ROLE_DEALER' && this.mainUser !== null) {
      return 'Sub-concessionario';
    }
    return UserRole[this.role];
  }

  get isUserCarMaker(): boolean {
    return this.role === 'ROLE_CARMAKER' || this.role === 'ROLE_CARMAKER_DR';
  }

  public static isRoleCarMaker(role: string): boolean {
    return role === 'ROLE_CARMAKER' || role === 'ROLE_CARMAKER_DR';
  }
}
