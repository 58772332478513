<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <section
    class="container-fluid bg-gray2 d-block overflow-auto h-100"
    :class="(listViews.includes($route.name) ? 'p-3' : 'p-5')"
  >
    <slot />
  </section>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'MainView',
  setup() {
    return {
      listViews: [
        'VehicleFramesList',
        'OrderList',
        'DocumentList',
        'ProductList',
        'UserList',
        'VehicleFrameStandardImport',
        'VehicleFrameTvvDrImport',
        'VehicleFrameTemplateDrImport',
        'VehicleFrameImportHistory',
        'ProductImport',
        'ProductImportHistory',
        'OrderEdit',
        'VehicleFrameAddToCart',
      ],
    };
  },
});
</script>
