/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import LoginPage from '@/views/Login.vue';
import Dashboard from '@/views/Dashboard/Dashboard.vue';
import UserList from '@/views/User/UserList.vue';
import ProductList from '@/views/Product/ProductList.vue';
import VehicleFrameList from '@/views/VehicleFrame/VehicleFrameList.vue';
import OrderList from '@/views/Order/OrderList.vue';
import DocumentList from '@/views/DocumentList.vue';
import CheckoutPage from '@/views/Checkout/Checkout.vue';
import {useUserStore} from '@/stores/UserStore';
import {useSessionStore} from '@/stores/SessionStore';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'LoginPage',
    path: '/',
    component: LoginPage,
    meta: {
      requiresAuth: false,
      title: 'Login - Trasformazioni Greenkar',
      cleanView: true,
    },
    beforeEnter: async () => {
      const sessionStore = useSessionStore();
      const userStore = useUserStore();

      if (!sessionStore.isLoginValid) {
        sessionStore.logout();
        return;
      }

      if (!userStore.currentUser.id) {
        await userStore.getCurrent();
      }

      return false;
    },
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Dashboard - Trasformazioni Greenkar',
    },
    children: [
      {
        name: 'NewsAdd',
        path: 'news/add',
        component: () => import('@/views/Dashboard/NewsDetail.vue'),
        meta: {
          requiresAuth: true,
          title: 'Aggiungi notizia',
        },
      },
      {
        name: 'NewsDetail',
        path: 'news/:newsId',
        component: () => import('@/views/Dashboard/NewsDetail.vue'),
        meta: {
          requiresAuth: true,
          title: 'Dettaglio notizia',
        },
      },
    ],
  },
  {
    name: 'UserList',
    path: '/user',
    component: UserList,
    meta: {
      requiresAuth: true,
      title: 'Lista utenti',
    },
  },
  {
    name: 'UserDetail',
    path: '/user/:userId',
    component: () => import('@/views/User/UserDetail.vue'),
    meta: {
      requiresAuth: true,
      title: 'Utenti',
      icon: 'people',
    },
    children: [
      {
        name: 'AddUserShippingAddress',
        path: 'address',
        component: () => import('@/views/User/AddUserShippingAddress.vue'),
        meta: {
          requiresAuth: true,
          title: 'Aggiunta indirizzo',
        },
      },
    ],
  },
  {
    name: 'Profile',
    path: '/profile',
    component: () => import('@/views/User/UserDetail.vue'),
    meta: {
      requiresAuth: true,
      title: 'Dettaglio concessionario',
    },
    children: [
      {
        name: 'AddUserProfileShippingAddress',
        path: 'address',
        component: () => import('@/views/User/AddUserShippingAddress.vue'),
        meta: {
          requiresAuth: true,
          title: 'Aggiunta indirizzo',
        },
      },
    ],
  },
  {
    name: 'AddUser',
    path: '/user/add',
    component: () => import('@/views/User/AddUser.vue'),
    meta: {
      requiresAuth: true,
      title: 'Dettaglio utente',
    },
  },
  {
    name: 'ProductList',
    path: '/product',
    component: ProductList,
    meta: {
      requiresAuth: true,
      title: 'Lista prodotti',
    },
    children: [
      {
        name: 'ProductImport',
        path: 'import',
        component: () => import('@/views/Product/ProductImport.vue'),
        meta: {
          requiresAuth: true,
          title: 'Importa prodotti',
        },
      },
      {
        name: 'ProductImportHistory',
        path: 'import-history',
        component: () => import('@/views/Product/ProductImportHistory.vue'),
        meta: {
          requiresAuth: true,
          title: 'Storico importazioni',
        },
      },
    ],
  },
  {
    name: 'ProductDetail',
    path: '/product/:productId',
    component: () => import('@/views/Product/ProductDetail.vue'),
    meta: {
      requiresAuth: true,
      title: 'Dettaglio prodotto',
    },
    children: [
      {
        name: 'ProductManualSelection',
        path: 'manual',
        component: () => import('@/views/Product/ProductManualSelection.vue'),
        meta: {
          requiresAuth: true,
          title: 'Manuali',
        },
      },
    ],
  },
  {
    name: 'VehicleFramesList',
    path: '/vehicle-frames',
    component: VehicleFrameList,
    meta: {
      requiresAuth: true,
      title: 'Lista telai',
    },
    children: [
      {
        name: 'VehicleFrameAddToCart',
        path: 'products/:vehicleFrameId',
        component: () => import('@/views/VehicleFrame/VehicleFrameAddToCart.vue'),
        meta: {
          requiresAuth: true,
          title: 'Prodotti telaio',
        },
      },
      {
        name: 'VehicleFrameStandardImport',
        path: 'import-standard',
        component: () => import('@/views/VehicleFrame/VehicleFrameImport.vue'),
        meta: {
          requiresAuth: true,
          title: 'Importa telai',
        },
      },
      {
        name: 'VehicleFrameTvvDrImport',
        path: 'import-tvv-dr',
        component: () => import('@/views/VehicleFrame/VehicleFrameImport.vue'),
        meta: {
          requiresAuth: true,
          title: 'Importa TVV DR',
        },
      },
      {
        name: 'VehicleFrameTemplateDrImport',
        path: 'import-template-dr',
        component: () => import('@/views/VehicleFrame/VehicleFrameImport.vue'),
        meta: {
          requiresAuth: true,
          title: 'Importa Template DR',
        },
      },
      {
        name: 'VehicleFrameImportHistory',
        path: 'import-history',
        component: () => import('@/views/VehicleFrame/VehicleFrameImportHistory.vue'),
        meta: {
          requiresAuth: true,
          title: 'Storico importazioni telai',
        },
      },
    ],
  },
  {
    name: 'VehicleFrameDetails',
    path: '/vehicle-frames/:vehicleFrameId',
    component: () => import('@/views/VehicleFrame/VehicleFrameDetails.vue'),
    meta: {
      requiresAuth: true,
      title: 'Dettaglio telaio',
    },
  },
  {
    name: 'OrderList',
    path: '/orders',
    component: OrderList,
    meta: {
      requiresAuth: true,
      title: 'Lista ordini',
    },
    children: [
      {
        name: 'OrderEdit',
        path: ':orderId/edit',
        component: () => import('@/views/Order/OrderEdit.vue'),
        meta: {
          requiresAuth: true,
          title: 'Modifica ordine',
        },
      },
    ],
  },
  {
    name: 'OrderDetail',
    path: '/orders/:orderId',
    component: () => import('@/views/Order/OrderDetail.vue'),
    meta: {
      requiresAuth: true,
      title: 'Dettaglio ordine',
      icon: 'file-earmark-ruled',
    },
    children: [
      {
        name: 'OrderDetailEdit',
        path: 'update',
        component: () => import('@/views/Order/OrderEdit.vue'),
        meta: {
          requiresAuth: true,
          title: 'Modifica ordine',
        },
      },
      {
        name: 'UploadOrderDocument',
        path: 'upload',
        component: () => import('@/views/Order/UploadOrderDocument.vue'),
        meta: {
          requiresAuth: true,
          title: 'Carica documento DCA',
        },
      },
      {
        name: 'DCARequest',
        path: 'request/:orderDetailId',
        component: () => import('@/views/Order/DCARequest.vue'),
        meta: {
          requiresAuth: true,
          title: 'Richiedi DCA',
        },
      },
      {
        name: 'InsertTestingDate',
        path: 'update/:orderDetailId/testing-date',
        component: () => import('@/views/Order/InsertTestingDate.vue'),
        meta: {
          requiresAuth: true,
          title: 'Inserisci data di collaudo',
        },
      },
      {
        name: 'UploadOrderDetailDocument',
        path: 'upload/:orderDetailId',
        component: () => import('@/views/Order/UploadOrderDetailDocument.vue'),
        meta: {
          requiresAuth: true,
          title: 'Carica Documento Dettaglio Ordine',
        },
      },
    ],
  },
  {
    name: 'DocumentList',
    path: '/documents',
    component: DocumentList,
    meta: {
      requiresAuth: true,
      title: 'Lista documenti',
    },
  },
  {
    name: 'CheckoutPage',
    path: '/checkout',
    component: CheckoutPage,
    meta: {
      requiresAuth: true,
      title: 'Checkout',
    },
    children: [
      {
        name: 'CheckoutCart',
        path: 'cart',
        component: () => import('@/views/Checkout/CheckoutCart.vue'),
        meta: {
          requiresAuth: true,
          title: 'Carrello',
        },
      },
      {
        name: 'CheckoutAddress',
        path: 'address',
        component: () => import('@/views/Checkout/CheckoutAddress.vue'),
        meta: {
          requiresAuth: true,
          title: 'Indirizzi',
        },
        children: [
          {
            name: 'CheckoutAddressSelection',
            path: 'select/:type',
            component: () => import('@/views/Checkout/CheckoutAddressSelection.vue'),
            meta: {
              requiresAuth: true,
              title: 'Indirizzi',
            },
          },
        ],
      },
      {
        name: 'CheckoutPayment',
        path: 'payment',
        component: () => import('@/views/Checkout/CheckoutPayment.vue'),
        meta: {
          requiresAuth: true,
          title: 'Pagamento',
        },
      },
      {
        name: 'CheckoutOrders',
        path: 'orders',
        component: () => import('@/views/Checkout/CheckoutOrders.vue'),
        meta: {
          requiresAuth: true,
          title: 'Ordini',
        },
      },
      {
        name: 'CheckoutDisclaimer',
        path: 'disclaimer',
        component: () => import('@/views/Checkout/CheckoutDisclaimer.vue'),
        meta: {
          requiresAuth: true,
          title: 'Disclaimer',
        },
      },
    ],
  },
  {
    name: 'PrivacyPolicy',
    path: '/privacy-policy',
    component: () => import('@/views/PrivacyPolicy.vue'),
    meta: {
      requiresAuth: false,
      title: 'Privacy Policy',
      cleanView: true,
    },
  },
  {
    name: 'CookiePolicy',
    path: '/cookie-policy',
    component: () => import('@/views/CookiePolicy.vue'),
    meta: {
      requiresAuth: false,
      title: 'Cookie Policy',
      cleanView: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  if (typeof to.meta.title === 'string') {
    document.title = to.meta.title;
  }
  const sessionStore = useSessionStore();
  const userStore = useUserStore();
  if (!to.meta.requiresAuth) {
    if (to.name === 'LoginPage' && sessionStore.isLoginValid) {
      await router.push({name: 'Dashboard'});
      return;
    }
    return;
  }
  if (!sessionStore.isLoginValid && sessionStore.canRefresh) {
    await sessionStore.refresh();
  }
  if (sessionStore.isLoginValid && !userStore.currentUser.id) {
    await userStore.getCurrent();
  }
  if (sessionStore.isLoginValid) {
    return;
  }
  return {
    name: 'LoginPage',
  };
});

export default router;
