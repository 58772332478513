<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="row ms-5 ps-3">
    <div class="col-12 d-flex justify-content-center align-items-center mb-5">
      <div class="w-50">
        <progress-bar />
      </div>
    </div>
    <div class="col-9 row">
      <router-view />
      <div v-if="$route.fullPath.includes('/checkout/address')">
        <div class="col-12 mt-3">
          <terms-widget
            ref="termsWidget"
          />
        </div>
      </div>
    </div>
    <div
      v-if="$route.name !== 'CheckoutOrders'"
      class="col-3"
    >
      <SummaryWidget />
      <button
        class="btn btn-dark w-100 p-3 text-uppercase"
        :disabled="loading"
        @click="goToNextStep"
      >
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm"
        />
        <span v-else>Continua con l'acquisto</span>
      </button>
    </div>
    <div
      v-else
      class="col-3"
    >
      <order-completed-widget />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useUserStore} from '@/stores/UserStore';
import {useCartStore} from '@/stores/CartStore';
import {useOrderStore} from '@/stores/OrderStore';
import {useToast} from 'vue-toastification';
import {AddressInterface, OrderCreationInterface} from '@/types/entities';
import TermsWidget from '@/components/Checkout/TermsWidget.vue';
import ProgressBar from '@/components/Checkout/ProgressBar.vue';
import SummaryWidget from '@/components/Checkout/SummaryWidget.vue';
import OrderCompletedWidget from '@/components/Checkout/OrderCompletedWidget.vue';
import ErrorHandlerService from '@/services/ErrorHandlerService';

export default defineComponent({
  name: 'CheckoutPage',
  components: {TermsWidget, ProgressBar, SummaryWidget, OrderCompletedWidget},

  setup() {
    return {
      userStore: useUserStore(),
      cartStore: useCartStore(),
      orderStore: useOrderStore(),
    };
  },
  data: function() {
    return {
      selectedShippingAddress: {} as AddressInterface,
      selectedInvoicingAddress: {} as AddressInterface,
      loading: false as boolean,
    };
  },
  async created() {
    await this.getUserCart();
    if (
      this.$route.name !== 'CheckoutOrders' &&
      this.cartStore.cart.products.length === 0
    ) {
      this.$router.push({name: 'Dashboard'});
    }
    this.userStore.selectedInvoicingAddress = {} as AddressInterface;
    this.userStore.selectedShippingAddress = {} as AddressInterface;
  },
  methods: {
    getUserCart: async function() {
      await this.cartStore.get();
    },
    goToNextStep: async function() {
      switch (this.$route.name) {
        case 'CheckoutCart':
          if (this.userStore.currentUser.role == 'ROLE_CARMAKER_DR') {
            await this.checkoutOrders();
            break;
          }
          this.$router.push({name: 'CheckoutAddress'});
          break;
        case 'CheckoutAddress':
          if (this.userStore.shippingAddresses.length == 0) {
            (useToast()).error('Per proseguire vai nella sezione "Profilo" e aggiungi un indirizzo di spedizione');
            break;
          }
          if (!this.userStore.selectedInvoicingAddress) {
            (useToast()).error('Per proseguire vai nella sezione "Profilo" e inserisci i Dati di Fatturazione');
            break;
          }
          if (!this.userStore.selectedShippingAddress) {
            if (!this.userStore.userOperations.cart?.dataSummarySection.shippingAddresEditButtonPopUp) {
              (useToast()).error('Per proseguire vai nella sezione "Profilo" e imposta un indirizzo di spedizione come principale');
              break;
            }
            (useToast()).error('Per proseguire devi selezionare un indirizzo di spedizione');
            break;
          }
          if (!(this.$refs.termsWidget as typeof TermsWidget).$data.termsHaveBeenAccepted) {
            (useToast()).error('Per proseguire devi accettare i termini');
            break;
          }
          if (!this.userStore.userOperations.cart?.payment && this.userStore.userOperations.cart?.disclaimer) {
            this.$router.push({name: 'CheckoutDisclaimer'});
          } else {
            this.$router.push({name: 'CheckoutPayment'});
          }
          break;
        case 'CheckoutPayment':
          await this.checkoutOrders();
          break;
      }
    },
    getAddress: async function() {
      await this.userStore.getAddresses(this.userStore.currentUser.id!, 'shipping');
      await this.userStore.getAddresses(this.userStore.currentUser.id!, 'invoicing');
      this.userStore.selectedShippingAddress = (await this.$userData.getAddresses(Number(this.userStore.currentUser.id!), 'shipping', true))[0];
      this.userStore.selectedInvoicingAddress = (await this.$userData.getAddresses(Number(this.userStore.currentUser.id!), 'invoicing', true))[0];
    },
    checkoutOrders: async function() {
      // Address-related actions are performed here for car-maker DR
      if (!this.userStore.selectedInvoicingAddress || !this.userStore.selectedInvoicingAddress.id ||
        !this.userStore.selectedShippingAddress || !this.userStore.selectedShippingAddress.id) {
        await this.getAddress();
      }
      if (!this.userStore.selectedShippingAddress) {
        (useToast()).error('Per proseguire vai nella sezione "Profilo" e imposta un indirizzo di spedizione come principale');
        return;
      }
      if (!this.userStore.selectedInvoicingAddress) {
        (useToast()).error('Per proseguire vai nella sezione "Profilo" e inserisci i Dati di Fatturazione');
        return;
      }
      if (!this.userStore.userOperations.cart?.payment) {
        this.orderStore.selectedPaymentMethod = 'wireTransfer';
      }
      this.loading = true;
      const order = {
        customer: this.userStore.currentUser.id,
        invoicingAddress: this.userStore.selectedInvoicingAddress.id,
        shippingAddress: this.userStore.selectedShippingAddress.id,
        paymentMethod: this.orderStore.selectedPaymentMethod,
      } as OrderCreationInterface;
      try {
        this.orderStore.orders = await this.$orderData.createOrder(order);
        await this.cartStore.get();
      } catch (error) {
        ErrorHandlerService.handle(error);
        return;
      } finally {
        this.loading = false;
      }
      this.$router.push({name: 'CheckoutOrders', query: {orderIds: this.orderStore.orders.map((order) => order.id).join(',')}});
    },
  },
});
</script>
