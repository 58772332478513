<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="d-flex justify-content-end mb-3">
    <router-link
      v-if="userStore.userOperations.user?.listView.addUserButtonView"
      class="btn btn-lavanda text-white me-2"
      :to="{ name: 'AddUser' }"
    >
      <span class="bi bi-plus-circle me-2" />
      Nuovo utente
    </router-link>
  </div>

  <div class="bg-gray1 d-inline-flex px-4 py-3 rounded-4 w-100">
    <bootstrap-table
      v-model="tableProps"
    />
    <router-view />
  </div>
</template>

<script lang="ts">
import {defineComponent, shallowRef} from 'vue';
import {BootstrapTableProps, UserRole as UserRoleEnum} from '@/types/entities';
import BootstrapTable from '@/components/BootstrapTable/BootstrapTable.vue';
import {useUserStore} from '@/stores/UserStore';
import userStatus from '@/components/User/TableData/UserStatus.vue';
import userRole from '@/components/User/TableData/UserRole.vue';
import userActions from '@/components/User/TableData/UserActions.vue';
import ErrorHandlerService from '@/services/ErrorHandlerService';

const userRoleFilterOptionsConfiguration = Object.keys(UserRoleEnum).map((key) => {
  if (key === 'ROLE_CARMAKER') {
    // For CarMaker filter returns also DR CarMakers
    return {
      value: 'ROLE_CARMAKER,ROLE_CARMAKER_DR',
      label: UserRoleEnum[key as keyof typeof UserRoleEnum],
    };
  }
  return {
    value: key,
    label: UserRoleEnum[key as keyof typeof UserRoleEnum],
  };
});

export default defineComponent({
  name: 'UserList',
  components: {BootstrapTable},
  setup() {
    return {
      userStore: useUserStore(),
    };
  },
  data() : {
    created: boolean,
    abortController: AbortController,
    tableProps: BootstrapTableProps
    } {
    return {
      created: false,
      abortController: new AbortController(),
      tableProps: {
        columns: [
          {
            title: 'Ragione sociale',
            field: 'businessName',
            sortable: false,
            filters: [
              {
                field: 'businessName',
                type: 'text',
              },
            ],
          },
          {
            title: 'Ruolo utente',
            field: 'role',
            sortable: true,
            component: shallowRef(userRole),
            filters: [
              {
                field: 'role',
                type: 'select',
                options: userRoleFilterOptionsConfiguration,
              },
            ],
          },
          {
            title: 'E-mail',
            field: 'email',
            sortable: false,
            filters: [
              {
                field: 'email',
                type: 'text',
              },
            ],
          },
          {
            title: 'Codice dealer',
            field: 'dealerCode',
            sortable: false,
            filters: [
              {
                field: 'dealerCode',
                type: 'text',
              },
            ],
          },
          {
            title: 'Stato',
            field: 'active',
            sortable: true,
            component: shallowRef(userStatus),
            filters: [
              {
                field: 'active',
                type: 'select',
                options: [
                  {
                    value: 'true',
                    label: 'Attivo',
                  },
                  {
                    value: 'false',
                    label: 'Non attivo',
                  },
                ],
              },
            ],
          },
          {
            title: 'Azioni',
            field: 'actions',
            sortable: false,
            component: shallowRef(userActions),
          },
        ],
        data: [],
        loading: false,
        sorting: {
          field: 'number',
          direction: 'desc',
        },
        activeFilters: [],
        pagination: {
          page: 1,
          perPage: 10,
        },
      },
    };
  },
  watch: {
    'tableProps.sorting': async function() {
      this.userStore.activeSorting = this.tableProps.sorting;
      await this.getList();
    },
    'tableProps.pagination': async function() {
      this.userStore.pagination = this.tableProps.pagination;
      await this.getList();
    },
    'tableProps.activeFilters': async function() {
      this.userStore.activeFilters = this.tableProps.activeFilters;
      if (this.created) this.tableProps.pagination.page = 1;
      await this.getList();
    },
  },
  created: async function() {
    if (this.userStore.activeSorting.field) {
      this.tableProps.sorting = this.userStore.activeSorting;
    }
    if (this.userStore.pagination.page) {
      this.tableProps.pagination = this.userStore.pagination;
    }
    if (this.userStore.activeFilters.length > 0) {
      this.tableProps.activeFilters = this.userStore.activeFilters;
    }
    await this.getList();
    this.created = true;
  },
  methods: {
    async getList() {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      try {
        this.tableProps.loading = true;
        await this.userStore.list(this.tableProps.pagination, this.tableProps.activeFilters, this.tableProps.sorting, this.abortController.signal);
        this.tableProps.data = this.userStore.users;
        this.tableProps.loading = false;
      } catch (error) {
        ErrorHandlerService.handle(error);
      }
    },
  },
});
</script>
