import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "nav nav-pills flex-column px-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
      return (_openBlock(), _createElementBlock("li", {
        key: link.name,
        class: "nav-item p-2"
      }, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["nav-link rounded-4 py-3 mx-2", [ _ctx.isLinkActive(link) ? 'active' : 'link-dark' ]]),
          to: {'name': link.routeName}
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(["bi px-2", [`bi-${link.icon}`]])
            }, null, 2),
            _createTextVNode(" " + _toDisplayString(link.name), 1)
          ]),
          _: 2
        }, 1032, ["class", "to"])
      ]))
    }), 128))
  ]))
}